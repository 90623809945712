export class SessionRol {
  id: number;
  rolName: string;
  description: string;

  constructor(_data: unknown) {
    const data = _data as SessionRol;
    this.id = data.id;
    this.rolName = data.rolName;
    this.description = data.description;
  }
}

export class RolID {
  globalAdmin: number;
  companyAdmin: number;
  warehouseAdmin: number;
  whOperator: number;
  whGuard: number;
  carrierAdmin: number;
  carrierOperator: number;
  customerSupport: number;

  constructor(_rolId: unknown) {
    const rolId = _rolId as RolID;
    this.globalAdmin = rolId.globalAdmin;
    this.companyAdmin = rolId.companyAdmin;
    this.warehouseAdmin = rolId.warehouseAdmin;
    this.whOperator = rolId.whOperator;
    this.whGuard = rolId.whGuard;
    this.carrierAdmin = rolId.carrierAdmin;
    this.carrierOperator = rolId.carrierOperator;
    this.customerSupport = rolId.customerSupport;
  }
}

export const Rols: SessionRol[] = [
  {
    id: 1,
    rolName: 'Global Admin',
    description: 'Global Admin',
  },
  {
    id: 2,
    rolName: 'Company admin',
    description: 'Company admin',
  },
  {
    id: 3,
    rolName: 'Warehouse admin',
    description: 'Warehouse admin',
  },
  {
    id: 4,
    rolName: 'Warehouse operator',
    description: 'Warehouse operator',
  },
  {
    id: 5,
    rolName: 'Warehouse guard',
    description: 'Warehouse guard',
  },
  {
    id: 6,
    rolName: 'Carrier administrator',
    description: 'Carrier administrator',
  },
  {
    id: 7,
    rolName: 'Carrier operator',
    description: 'Carrier operator',
  },
  {
    id: 8,
    rolName: 'Customer Support Personal',
    description: 'Company Inquiry CSP CustomerSupportPersonal',
  },
];

export const rolId: RolID = {
  globalAdmin: 1,
  companyAdmin: 2,
  warehouseAdmin: 3,
  whOperator: 4,
  whGuard: 5,
  carrierAdmin: 6,
  carrierOperator: 7,
  customerSupport: 8,
};
