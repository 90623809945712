export const environment = {
  production: false,
  apiUrl: 'https://qa-api-glps.o-i.com/api',
  baseUrl: 'https://qa-api-glps.o-i.com',
  authUrl: 'https://oidevadb2c.b2clogin.com/oidevadb2c.onmicrosoft.com',
  scopeUrl: 'https://oidevadb2c.onmicrosoft.com',
  clientId: 'b6dc4c9d-24b7-4229-979b-d520842e5b3a',
  redirectUri:
    'https://qa-glps.o-i.com',
  VERIFIER_KEY: 'code-aNfjc7O5bd6a',
  AUTHDATA_KEY: 'auth-nrAeHOuRNPL5',
  SESSION_KEY: 'session-rPDtTuC4NjoE',
  APP_REGISTRATION_FLOW: 'B2C_1_qa-GLPS-flow',
  AUD_ID: '2124df2f-0f81-45d2-8426-d0473ffd4a81',
};
