import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { SessionModel } from '../models/session.model';

const SESSION_URL = `${environment.apiUrl}/users/in_session`;

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private sessionLocalStorageToken = environment.SESSION_KEY;

  constructor(private http: HttpClient) {}

  private setSessionDataFromLocalStorage(sessionData: SessionModel) {
    localStorage.setItem(
      this.sessionLocalStorageToken,
      JSON.stringify(sessionData),
    );
  }

  /*  Session method */
  getSessionData() {
    return this.http.get<SessionModel>(SESSION_URL).pipe(
      map(data => {
        this.setSessionDataFromLocalStorage(data);
      }),
    );
  }

  getSessionDataFromLocalStorage(): SessionModel | undefined {
    try {
      const value = localStorage.getItem(this.sessionLocalStorageToken);
      return value ? JSON.parse(value) : undefined;
    } catch (error) {
      console.error('error', error);
      return undefined;
    }
  }
}
